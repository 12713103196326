import Service from "../Service";
import Vue from "vue";
const resource = "/Log/";

export default {
    SaveConfig(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    GetRecord(requestID) {
        return Service.post(resource + "GetRecord", "", {
            params: { requestID: requestID },
        });
    },

    sendMessage(requestID) {
        return Service.post("general/" + "sendmessage", "", {
            params: { requestID: requestID },
        });
    }
};