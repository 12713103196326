<template>
	<div>
		<v-container class="px-0" fluid>
			<v-row>
				<v-col cols="12">
					<s-toolbar
						color="primary"
						dark
						label="Log de Errores"
					></s-toolbar>
					<v-card>
						<s-text hidden label="ID" v-model="LogID"></s-text>
						<v-row style="margin-top: 4px; margin-left: 10px;">
							<v-col cols="12" lg="6" md="6">
								<s-text
									v-model="RouteFile"
									label="Ruta de File"
								></s-text>
							</v-col>
							<v-col cols="12" lg="6" md="6">
								<s-text
									v-model="FileName"
									label="Name de File"
								></s-text>
							</v-col>
							<v-col cols="4" lg="2" md="2">
								<v-checkbox
									style="margin:0px;padding:0px;"
									v-model="chkException"
									label="Excepcion"
									color="success"
								>
								</v-checkbox>
							</v-col>
							<v-col cols="4" lg="2" md="2">
								<v-checkbox
									style="margin:0px;padding:0px;"
									v-model="chkUserID"
									label="User ID"
									color="success"
								>
								</v-checkbox>
							</v-col>
							<v-col cols="4" lg="2" md="2">
								<v-checkbox
									style="margin:0px;padding:0px;"
									v-model="chkNamePC"
									label="Name PC"
									color="success"
								>
								</v-checkbox>
							</v-col>
							<v-col cols="4" lg="2" md="2">
								<v-checkbox
									style="margin:0px;padding:0px;"
									v-model="chkIPLocal"
									label="IP Local"
									color="success"
								>
								</v-checkbox>
							</v-col>
							<v-col cols="4" lg="2" md="2">
								<v-checkbox
									color="success"
									style="margin:0px;padding:0px;"
									v-model="chkDateHour"
									label="Date / Hour"
								>
								</v-checkbox>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-btn small block color="primary" @click="btnSave()">
									Guardar
								</v-btn>
							</v-col>
						</v-row>

						<!-- <v-row>
							<v-col>
								<v-btn
									small
									block
									color="warning"
									@click="sendMessage()"
								>
									Enviar mensaje WhatsApp
								</v-btn>
							</v-col>
						</v-row> -->
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import SText from "../../../components/Utils/SText.vue";
	//Service
	import _sLogErrorsService from "../../../services/General/LogErrorsService";
	export default {
		components: { SText },
		data() {
			return {
				LogID: 0,
				RouteFile: "",
				FileName: "",
				chkException: true,
				chkUserID: true,
				chkNamePC: true,
				chkIPLocal: true,
				chkDateHour: true,

				item: [],
			};
		},

		created() {
			this.initialize();
		},
		watch: {
			value() {
				this.initialize();
			},
		},

		methods: {
			initialize() {
				this.GetRecord();
			},
			GetRecord() {
				_sLogErrorsService.GetRecord(this.$fun.getUserID()).then(r => {
					this.LogID = r.data.LogID;
					this.RouteFile = r.data.RouteFile;
					this.FileName = r.data.FileName;
					this.chkException = r.data.Exception;
					this.chkUserID = r.data.CurrentId;
					this.chkNamePC = r.data.NamePc;
					this.chkIPLocal = r.data.IPLocal;
					this.chkDateHour = r.data.DateHour;
				});
			},

			btnSave() {
				if (this.RouteFile.length == 0) {
					this.$fun.alert("Debe Ingresar una ruta", "warning");
					return;
				}

				if (this.FileName.length == 0) {
					this.$fun.alert("Debe Ingresar nombre de File", "warning");
					return;
				}

				this.item = {
					LogID: this.LogID,
					RouteFile: this.RouteFile,
					FileName: this.FileName,
					Exception: this.chkException ? 1 : 0,
					CurrentId: this.chkUserID ? 1 : 0,
					NamePc: this.chkNamePC ? 1 : 0,
					IPLocal: this.chkIPLocal ? 1 : 0,
					DateHour: this.chkDateHour ? 1 : 0,
				};

				this.$fun.alert("Seguro de Guardar?", "question").then(val => {
					if (val.value) {
						_sLogErrorsService
							.SaveConfig(this.item, this.$fun.getUserID())
							.then(r => {
								this.$fun.alert("Guardado Correctamente", "success");
								this.GetRecord();
							});
					}
				});
			},

			/* sendMessage(){
				this.$fun.alert("Seguro de enviar mensaje de WhatsApp", "question").then(r => {
					if(r.value){
						_sLogErrorsService.sendMessage(this.$fun.getUserID()).then(data => {
							if(data.status == 200){
								console.log("data",data);
							}
							
						})
					}
				})
			}, */
		},
	};
</script>

<style></style>
